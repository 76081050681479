<template>
  <div id="crm-calendar-settings-appointment-types">
    <div class="table-custom">
      <b-table v-if="!results.isLoading" responsive hover :items="results.records" :fields="results.fields" empty-text="no types" show-empty>
        <template #cell(options)="data">
          <div v-if="data.item.userId != null" class="text-right">
            <b-link>Edit</b-link>
          </div>
        </template>
        <template #cell(isDefault)="data">
          <b-form-checkbox disabled v-model="data.item.isDefault" name="check-button" switch/>
        </template>
      </b-table>
      <div v-else style="padding: 20px;"><b-spinner label="Loading..." small></b-spinner></div>
    </div>
  </div>
</template>

<script>
export default {
    components: {
    },
    data(){
        return {
          results: {
            isLoading: false,
            fields: [
              {
                key: 'name',
                label: "Name"
              },
              {
                key: 'isDefault',
                label: "Default"
              },
              {
                key: 'options',
                label: ""
              },
            ],
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },

    methods:{

    },

    mounted(){
      this.$api.get(`crm/calendar/event-types`,{}).then(res => {
        let {records, pagination, total} = res.data;
        this.results.isLoading = false;
        this.results.records = records;
        this.results.pagination = pagination;
        this.results.total = total;
      }).catch((e) => {console.log(e,'cannot get event types')});
    }
};
</script>
