<template>
  <div v-if="!loading" id="crm-calendar-settings-weekdays">
    <b-modal ref="modalWeekday" id="modal-weekday" :title="selectedDay ? selectedDay.name : ''" @hide="onModalClose()" @ok="onModalSave()">
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <div class="float-left" v-if="errorMessage">
            <BIconInfoCircle variant="danger"/> <span class="text-danger">{{errorMessage}}</span>
          </div>
          <div class="float-right">
            <b-button size="sm" variant="outline-secondary" @click="cancel()" class="mr-2">
              Cancel
            </b-button>
            <b-button :disabled="!canAddSlot" variant="success" size="sm" @click="ok()">
              <BIconPlus class="mr-1"/> Add
            </b-button>
          </div>
        </div>
      </template>

      <template v-if="selectedDay">
        <DropdownAppointmentTypes :selectedId="onlineMeetingId" @onSelected="setSelectedAppointmentType"/>

        <template v-if="selectedDay.slot.eventType">
          <b-row>
            <b-col>
              <label for="demo-sb">Slot in minutes ({{selectedDay.slot.slotStepFormatted}})</label>
              <b-form-spinbutton id="demo-sb" size="sm" v-model="selectedDay.slot.slotStep" @change="onSlotStepChange" min="15" max="120" step="15"></b-form-spinbutton>
            </b-col>
            <b-col>
              <div class="mb-2">Time range:</div>
              <div class="d-inline-block">
                <b-form-timepicker
                  size="sm"
                  v-model="selectedDay.slot.hmStart"
                  button-only
                  left
                  locale="de"
                  aria-controls="hmStart-input"
                  button-variant="light"
                  minutes-step="15"
                  hide-header
                  no-close-button
                  @context="onSelectedHM('start', $event)"
                ></b-form-timepicker>
                {{selectedDay.slot.hmStartFormatted}}
              </div>
              <div class="d-inline-block ml-3 mr-3">-</div>
              <div class="d-inline-block">
                <b-form-timepicker
                  size="sm"
                  v-model="selectedDay.slot.hmEnd"
                  button-only
                  left
                  locale="de"
                  aria-controls="hmStart-input"
                  button-variant="light"
                  minutes-step="15"
                  hide-header
                  no-close-button
                  @context="onSelectedHM('end', $event)"
                ></b-form-timepicker>
                {{selectedDay.slot.hmEndFormatted}}
              </div>
            </b-col>
          </b-row>

          <div class="mt-3 mb-2 pb-2 border-bottom"><strong>{{selectedDay.slot.slotsNumber}} slots were generated:</strong></div>
          <ul class="list-unstyled row">
            <li v-for="(item,key) in selectedDay.hours" :key="key" class="list-item col-4 py-2">
              <b-button variant="outline-primary" size="sm" class="d-inline-block">
                <BIconClockFill class="mr-3"/>{{item.start}} - {{item.end}}
              </b-button>
              <!--
              <b-row>
                <b-col cols="4">
                  <b-button :disabled="!item.enabled" variant="outline-primary" size="sm" class="d-inline-block" @click="item.enabled = !item.enabled">
                    <BIconClockFill class="mr-3"/>{{item.start}} - {{item.end}}
                  </b-button>
                </b-col>
                <b-col>
                  <b-form-checkbox size="lg" v-model="item.enabled" name="check-button" switch/>
                </b-col>
              </b-row>
            -->
            </li>
          </ul>
        </template>
      </template>
    </b-modal>
    <table class="table table-borderless">
      <tr v-for="(day,key) in weekdays" :key="'tr-'+key">
        <td class="border-bottom pl-2 pr-2 pt-3 pb-3" style="width: 220px;">
          <b-row>
            <b-col>
              <b-form-checkbox v-model="day.enabled" name="check-button" switch>
                <span class="d-inline-block" style="padding-top: 2px;">{{day.name}}</span>
              </b-form-checkbox>
            </b-col>
            <b-col class="text-right">
              <b-button v-if="day.enabled" variant="link" size="sm" class="p-0 btn-outline-none" @click="openModal(day)">
                <BIconPlus font-scale="1.6"/>
              </b-button>
            </b-col>
          </b-row>
        </td>
        <td class="border-bottom pl-2 pr-2 pt-3 pb-3">
          <div v-if="day.enabled">
            <span v-if="day.slots.length === 0" class="text-muted d-inline-block pt-1">not defined</span>
            <template v-else>
              <b-row v-for="(slot, sk) in day.slots" :key="'slot-'+sk" class="mb-2 pb-2">
                <b-col cols="3">
                  <b-button variant="outline-secondary" size="sm" block>
                    <BIconClockFill class="mr-3"/>
                    {{slot.hmStartFormatted}} - {{slot.hmEndFormatted}}
                  </b-button>
                </b-col>
                <b-col cols="7">
                  <b-button class="ml-3 text-left" variant="outline-secondary" size="sm" block>
                    <b-badge class="mr-3" style="width: 80px;">{{slot.slotStepFormatted}} slot</b-badge>
                    <b-badge class="mr-3" style="width: 80px;">{{slot.slotsNumber}} slots</b-badge>
                    {{slot.eventType.name}}
                  </b-button>
                </b-col>
                <b-col cols="2">
                  <b-button variant="link" size="sm" class="p-0 ml-3" @click="deleteDaySlot(day.id,slot.id)"><BIconX font-scale="1.6" variant="danger"/></b-button>
                </b-col>
              </b-row>
            </template>
          </div>
          <div v-else>
            <span class="text-muted d-inline-block pt-1">disabled</span>
          </div>
        </td>
      </tr>
    </table>

    <b-button @click="saveChanges()" variant="primary">Update</b-button>
  </div>
  <div v-else><b-spinner label="Loading..." small></b-spinner></div>
</template>

<script>
import moment from 'moment';
import DropdownAppointmentTypes from './Dropdown.Appointment.Types';
import {BIconPlus,BIconInfoCircle,BIconClockFill,BIconX} from 'bootstrap-vue';
export default {
    components: {
      BIconPlus,BIconInfoCircle,BIconClockFill,BIconX,
      DropdownAppointmentTypes
    },
    data(){
        return {
          onlineMeetingId: "ca303fc3d28844b4688b",
          weekdays: [],
          selectedDay: null,
          errorMessage: null,
          canAddSlot: true,
          loading: false
        };
    },

    methods:{
      openModal(day){
        let lastSlot,startTime,endTime = null;
        if(day.slots.length > 0){
          lastSlot = day.slots[day.slots.length - 1];
          startTime = moment(moment().format('YYYY-MM-DD')+' '+lastSlot.hmEnd).add(60,'minutes');
          endTime = startTime.clone().add(60,'minutes');
        }

        this.selectedDay = {
          id: day.id,
          name: day.name,
          slot: {
            hmStart: startTime ? startTime.format('HH:mm:ss') : "07:00:00",
            hmStartFormatted: startTime ? startTime.format('HH:mm') : "07:00",
            hmEnd: endTime ? endTime.format('HH:mm:ss') : "12:00:00",
            hmEndFormatted: endTime ? endTime.format('HH:mm') : "12:00",
            slotStep: 60,
            slotStepFormatted: "1h",
            slotsNumber: lastSlot ? 1 : 5,
            eventType: null
          },
          hours: []
        };
        this.selectedDay.hours = this.enumerateHours();
        this.$refs['modalWeekday'].show();
      },
      onModalClose(){
        this.selectedDay = null;
        this.errorMessage = null;
      },
      onModalSave(){
        let day = this.weekdays.find(o => o.id === this.selectedDay.id);
        day.slots.push(Object.assign(this.selectedDay.slot,{id: Math.random().toString(36).substr(2, 9)}));
        day.slots = day.slots.sort((a, b) => (a.hmStart < b.hmStart) ? -1 : ((a.hmStart > b.hmStart) ? 1 : 0));

        this.selectedDay = null;
        this.errorMessage = null;
      },

      checkIfInRange(){
        let nowYMD = moment().format('YYYY-MM-DD');
        let day = this.weekdays.find(o => o.id === this.selectedDay.id);
        let isFree = true;
        for(let i in day.slots){
          let slot = day.slots[i];
          for(let j in this.selectedDay.hours){
            let h = this.selectedDay.hours[j];
            if(moment(moment(nowYMD+' '+h.start).add(1,'second')).isBetween(moment(nowYMD+' '+slot.hmStart),moment(nowYMD+' '+slot.hmEnd))){
              isFree = false;
              break;
            }
          }
        }
        if(!isFree){
          this.errorMessage = "One or multiple slots are already in use";
          this.canAddSlot = false;
        }
        else{
          this.errorMessage = null;
          this.canAddSlot = true;
        }
      },

      onSelectedHM(type,ctx){
        let startDT = moment(moment().format('YYYY-MM-DD')+' '+this.selectedDay.slot.hmStart);
        let endDT = moment(moment().format('YYYY-MM-DD')+' '+this.selectedDay.slot.hmEnd);
        if(endDT.diff(startDT) <= 0){
          let newEnd = startDT.add(this.selectedDay.slot.slotStep,'minutes');
          this.selectedDay.slot = Object.assign(this.selectedDay.slot,{
            hmEnd: newEnd.format("HH:mm:ss"),
            hmEndFormatted: newEnd.format("HH:mm"),
          })
        }

        if(type === "start"){
          this.selectedDay.slot = Object.assign(this.selectedDay.slot,{
            hmStart: ctx.value,
            hmStartFormatted: ctx.formatted,
          })
        }

        if(type === "end"){
          this.selectedDay.slot = Object.assign(this.selectedDay.slot,{
            hmEnd: ctx.value,
            hmEndFormatted: ctx.formatted,
          })
        }

        this.selectedDay.hours = this.enumerateHours();
        this.checkIfInRange();
      },
      onSlotStepChange(){
        this.selectedDay.slot.slotStepFormatted = this.timeConvert(this.selectedDay.slot.slotStep);
        this.selectedDay.hours = this.enumerateHours();
      },
      deleteDaySlot(dayId,slotId){
        let day = this.weekdays.find(o => o.id === dayId);
        day.slots = day.slots.filter(o => o.id !== slotId).sort((a, b) => (a.hmStart < b.hmStart) ? -1 : ((a.hmStart > b.hmStart) ? 1 : 0));
      },

      timeConvert(n) {
        let num = n;
        let hours = (num / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);

        if(rhours === 0){
          return rminutes + "m";
        }
        if(rhours > 0 && rminutes === 0){
          return rhours + "h";
        }

        return rhours + "h " + rminutes + "m";
      },

      countSlotsNo(){
        let startDT = moment(moment().format('YYYY-MM-DD')+' '+this.selectedDay.slot.hmStart);
        let endDT = moment(moment().format('YYYY-MM-DD')+' '+this.selectedDay.slot.hmEnd);
        let duration = moment.duration(endDT.diff(startDT));
        let minutes = duration.asMinutes();
        return minutes / this.selectedDay.slot.slotStep;
      },

      enumerateHours(){
        let hours = [];
        let firstHour = moment(moment().format('YYYY-MM-DD')+' '+this.selectedDay.slot.hmStart);
        let lastHour = moment(moment().format('YYYY-MM-DD')+' '+this.selectedDay.slot.hmEnd);
        let slot = 0;
        let i = 0;
        while(firstHour.add(slot, 'minutes').diff(lastHour) < 0) {
          if(i>0){
            slot = this.selectedDay.slot.slotStep;
          }
          let startTime = firstHour.clone();

          slot = this.selectedDay.slot.slotStep;
          let endTime = startTime.clone().add(slot,'minutes');

          if(endTime === "00:00"){
            break;
          }

          hours.push({
            start: startTime.format('HH:mm'),
            end: endTime.format('HH:mm'),
            enabled: true
          });
          i++;
        }

        this.selectedDay.slot.slotsNumber = hours.length;

        let firstSlot = hours[0];
        let lastSlot = hours[hours.length - 1];

        if(firstSlot){
          this.selectedDay.slot.hmStart = firstSlot.start;
        }
        if(lastSlot){
          this.selectedDay.slot.hmEnd = lastSlot.end;
        }

        return hours;
      },

      setSelectedAppointmentType({selected}){
        this.selectedDay.slot.eventType = selected;
      },

      saveChanges(){
        this.$api.post(`crm/calendar/user/settings`,{
          data: {
            days: this.weekdays.map(day => {
              return {
                isoWeekday: day.isoWeekday,
                enabled: day.enabled,
                slots: day.slots.map(slot => {
                  return {
                    hmStart: slot.hmStart,
                    hmEnd: slot.hmEnd,
                    step: slot.slotStep,
                    stepFormatted: slot.slotStepFormatted,
                    slotsNumber: slot.slotsNumber,
                    eventTypeId: slot.eventType.id,
                  }
                })
              };
            })
          }
        }).catch((e) => {console.log(e,'cannot save calendar user settings')});
      }
    },

    mounted(){
      this.loading = true;
      this.$api.get(`crm/calendar/user/settings`).then(res => {
        let records = res.data;

        this.loading = false;
        let nowYMD = moment().format('YYYY-MM-DD');
        this.weekdays = Array.apply(null, Array(7)).map(function (_, i) {
          let day = moment(i, 'e').startOf('week').isoWeekday(i + 1);
          let savedDay = records.find(o => o.isoWeekday === day.isoWeekday());
          let slots = [];
          if(savedDay !== undefined && savedDay.slots.length > 0){
            slots = savedDay.slots.map(slot => {
              return Object.assign(slot,{
                id: Math.random().toString(36).substr(2, 9),
                slotStep: slot.step,
                slotStepFormatted: slot.stepFormatted,
                hmStartFormatted: moment(nowYMD +' '+ slot.hmStart).format('HH:mm'),
                hmEndFormatted: moment(nowYMD+' '+slot.hmEnd).format('HH:mm'),
              });
            })
          }
          return {
            id: Math.random().toString(36).substr(2, 9),
            isoWeekday: day.isoWeekday(),
            name: day.format('dddd'),
            enabled: savedDay !== undefined ? savedDay.enabled : true,
            slots: slots.sort((a, b) => (a.hmStart < b.hmStart) ? -1 : ((a.hmStart > b.hmStart) ? 1 : 0))
          };
        });

      }).catch((e) => {console.log(e,'cannot get calendar user settings')});
    }
};
</script>
