<template>
  <b-dropdown v-if="!loading" class="mb-3" size="sm" variant="outline-primary" id="dropdown-appointment-type" :text="selected ? selected.name : ''" block>
    <b-dropdown-item v-for="(item,key) in eventTypes" :key="'cd'+key" :active="item.active" @click="setSelected(item.id)">{{item.name}}</b-dropdown-item>
  </b-dropdown>
  <div v-else><b-spinner label="Loading..." small></b-spinner></div>
</template>

<script>
export default {
  props: ['selectedId'],
  components: {
  },
  data(){
      return {
        loading: false,
        selected: null,
        eventTypes: []
      }
  },

  methods:{
    setSelected(id){
      this.selected = this.eventTypes.find(o => o.id === id);
      this.eventTypes = this.eventTypes.map(ev => {
        ev.active = ev.id === id;
        return ev;
      });
      this.$emit('onSelected', {selected: this.selected});
    }
  },

  mounted(){
    this.loading = true;
    this.$api.get(`crm/calendar/event-types`,{}).then(res => {
      this.loading = false;
      this.eventTypes = res.data.records.map(item => {
        item.active = item.id === this.selectedId;
        return item;
      });
      this.setSelected(this.selectedId);
    }).catch((e) => {console.log(e,'cannot get event types')});
  }
};
</script>
