<template>
    <div id="crm-calendar-settings">
      <PageMenu :breadcrumbs="breadcrumbs" :page="page">
        <template v-slot:toolbar>
          <b-button variant="primary" size="sm" :to='{name: "crm.calendar"}'><BIconCalendarWeek class="mr-2"/> Calendar</b-button>
        </template>
      </PageMenu>
      <div class="page-with-menu">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Availability Settings">
              <ListWeekdaysSettings/>
            </b-tab>
            <b-tab title="Appointment Types">
              <ListAppointmentTypes/>
            </b-tab>
            <!--
            <b-tab title="E-Mail Settings">
              <EmailSettings/>
            </b-tab>
          -->
          </b-tabs>
        </b-card>
      </div>
    </div>
</template>

<script>
import {BIconCalendarWeek} from 'bootstrap-vue';
import ListWeekdaysSettings from './../../components/CalendarSlim/components/List.Weekdays.Settings';
import ListAppointmentTypes from './../../components/CalendarSlim/components/List.Appointment.Types';
//import EmailSettings from './../../components/CalendarSlim/components/Email.Settings';
export default {
    props: ['user'],
    components: {
      BIconCalendarWeek,
      ListWeekdaysSettings,ListAppointmentTypes,
      PageMenu: () => import('@/common/components/PageMenu'),
    },
    data(){
        return {
          page: {
              title: "Calendar Settings",
          },
          breadcrumbs: [
              {
                  title: "Dashboard",
                  url: this.$router.resolve({name: "app.dashboard"}).href
              },
              {
                  title: "Calendar",
                  url: this.$router.resolve({name: "crm.calendar"}).href
              },
              {
                  title: "Calendar Settings",
              }
          ],
        };
    },

    methods:{
      notifyParent(){
        let data = {active: "crm.calendar"};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },
    },

    mounted(){
        this.notifyParent();
    },
};
</script>
